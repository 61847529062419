<template>
  <div class="lab-links">
    <div class="lab-links__subscribe">
      <a
        v-if="canLaunch"
        class="lab-links__btn-play ui-btn --style-1"
        :href="isMobileConst ? undefined : launchLink"
        target="_blank"
        @click="launchApp"
      >
        {{ t("labs.launch") }}
        <IconPlay />

        <div
          v-if="isMobileConst"
          class="lab-links__counter"
        >
          ?
        </div>
      </a>

      <template v-else>
        <button
          class="lab-links__btn-play ui-btn --style-1"
          @click="handleSubscribeClick"
        >
          {{ t("labs.subscribe") }}
          <ShopCart />
        </button>

        <ElTooltip
          v-if="product.demo_mode"
          class="box-item"
          effect="light"
          placement="top"
          :content="isUser ? demoCounterTooltipText : t('tooltipLogIn')"
          :disabled="isMobileConst && hasDemoLaunches"
        >
          <a
            class="lab-links__btn-play ui-btn --style-1"
            :class="{ 'mobile': isMobileConst, 'disabled': isDemoDisabled }"
            :href="isDemoDisabled || isMobileConst ? undefined : launchLink"
            target="_blank"
            @click="launchDemoApp"
          >
            {{ t("labs.demo") }}
            <IconPlay />

            <div
              v-if="!isDemoDisabled"
              class="lab-links__counter"
            >
              {{ isMobileConst ? '?' : demoCounter }}
            </div>
          </a>
        </ElTooltip>
      </template>
    </div>
    <div class="lab-links__subscribe">
      <a
        v-for="link in buttonLinks"
        :key="`link-${link.id}`"
        class="lab-links__btn-link ui-btn --style-2"
        :href="link.locales[0].url"
      >
        {{ link.locales[0].caption }}
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { ElTooltip } from 'element-plus';
import IconPlay from '@/components/icons/PlayBtn.vue';
import { LabData } from '@/types/LabData';
import { catchErrorShow, getStorageItem, pluralNoun, setStorageItem, isMobile } from '@/utils';
import { loginUser } from '@/services/userActions';
import services from '@/router/Laboratories/services';
import { names } from '@/constants/routes';
import ShopCart from '@/components/icons/ShopCart.vue';

const { t } = useI18n();
const store = useStore();
const router = useRouter();
const isMobileConst = isMobile();

const emit = defineEmits<{
  (e: 'showMobileModal'): void;
}>();

const props = defineProps<{
  product: LabData;
}>();

const canLaunch = computed(() => props.product?.active_for_user);
const buttonLinks = computed(() => props.product.links.slice().sort((a, b) => a.order - b.order));
const isUser = computed(() => store.getters.isAuthed);
const isOrgOwner = computed(() => store.getters.isOrgOwner);
const isOrgModerator = computed(() => store.getters.isOrgModerator);
const orgId = computed(() => store.getters.organizationId);

const launchLink = computed(() => {
  if (
    !props.product?.uuid
    || (props.product.demo_mode && !props.product.demo_counter)
  ) {
    return undefined;
  }

  return props.product.demo_mode
    ? `/api/product/launch-as-demo/${props.product.uuid}?is_mobile=${Number(isMobileConst)}`
    : `/api/product/launch/${props.product.uuid}?is_mobile=${Number(isMobileConst)}`;
});

const launchApp = () => {
  if (isMobileConst) {
    handleMobileLaunch();
  }
};

const launchDemoApp = () => {
  if (!isUser.value) {
    return loginUser();
  }

  if (!hasDemoLaunches.value) {
    return;
  }

  if (isMobileConst) {
    handleMobileLaunch();
  } else {
    decreaseDemoCounting();
  }
};

const decreaseDemoCounting = () => {
  setTimeout(() => {
    store.commit('Common/SET_DEMO_COUNTER', demoCounter.value - 1);
  }, 0);
};

const pushMobileStatistics = () => {
  if (props.product.uuid) {
    try {
      services.sendAccountingAppUrl(props.product.uuid, isMobileConst, props.product.demo_mode);
    } catch (err) {
      catchErrorShow(err, t);
    }
  }
};

const handleMobileLaunch = () => {
  emit('showMobileModal');
  pushMobileStatistics();
};

const demoCounter = computed(() => store.getters['Common/demoCounter']);
const hasDemoLaunches = computed(() => demoCounter.value && Number(demoCounter.value) > 0);
const isDemoDisabled = computed(
  () => !isUser.value
      || !hasDemoLaunches.value
      || !launchLink.value
);

watch(() => props.product, (product) => {
  store.commit('Common/SET_DEMO_COUNTER', product.demo_counter);
}, { once: true });

const demoCounterTooltipText = computed(() => {
  if (!hasDemoLaunches.value) {
    return t('tooltipLaunchHasLimit');
  }
  return t(
    'youHaveNLaunchesLeft',
    {
      nn: demoCounter.value || 0,
    },
    pluralNoun(demoCounter.value || 0),
  );
});

const handleSubscribeClick = () => {
  if (isOrgOwner.value || isOrgModerator.value) {
    router.push(`/admin/organization/${orgId.value}/orders`);

    return;
  }

  // Get from local storage and covert into Set for unique values
  const basket = new Set(getStorageItem<number[]>('basket') || []);
  if (props.product?.offer?.id) {
    // Add offer id of product to select them in shop
    basket.add(props.product?.offer?.id);
  }
  // Update basket
  setStorageItem('basket', Array.from(basket.values()));

  // Go into shop
  router.push({ name: names.shop });
};

</script>

<style lang="scss">
@import "@/assets/style/include.scss";

.lab-links {
  width: 100%;
  position: absolute;
  bottom: 0;

  &__counter {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    right: 0;
    border: 2px solid $color-white;
    line-height: 16px;
    text-align: center;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background-color: #00C2FF;
    color: $color-white;
    font-size: 10px;
    font-weight: 900;
  }

  &__subscribe {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: center;
    margin: 10px auto;
  }

  &__btn-play {
    height: 36px;
    font-size: 15px;
    font-weight: 700;
    border-radius: 8px;

    &:hover .lab-links__counter {
      background-color: #57BAD9;
    }

    &:active .lab-links__counter {
      background-color: #267C98;
    }
  }

  &__btn-link {
    height: 36px;
    font-size: 14px;
    font-weight: 400;
    border-radius: 8px;
  }

  .lab-links__btn-play, .lab-links__btn-link {

    svg {
      width: 25px;
      height: 21px;
      margin-left: 5px;
    }

    &.el-button+.el-button {
      margin-left: 0;
    }
  }
}

</style>
