<template>
  <div class="licence-info">
    <div
      v-if="expirationDate"
      class="licence-info-text"
    >
      <div
        v-if="isSoonExpire"
        class="licence-info-alert"
      />
      {{ expirationDate }}
    </div>

    <ElTooltip
      v-if="showStatistics"
      class="box-item"
      effect="light"
      :content="tooltipSpendTime"
      rawContent
      placement="bottom"
    >
      <div
        class="licence-info__calendar"
        :class="{ '--small': expirationDate }"
      >
        <Calendar />
        <span class="licence-info__calendar-text">{{ t('statistics') }}</span>
      </div>
    </ElTooltip>
  </div>
</template>
<script setup lang="ts">
import { ElTooltip } from 'element-plus';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import Calendar from '@/components/icons/CalendarClock.vue';
import dayjs from '@/utils/dayjs';
import { LabData } from '@/types/LabData';
import { formatDate } from '@/utils';
import { LicenseState } from '@/router/Laboratories/Laboratory/constants';

const { t } = useI18n();

const props = defineProps<{
  product: LabData;
}>();

const formatTime = (seconds: number, isTooltipText: boolean = false): string => {
  if (!seconds) { return ''; }
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  let time = '';

  if (hours && !isTooltipText) {
    time = `${hours} ${t('time.hour')}. `;
  } else {
    time = `<b>${hours}</b> ${t('time.hour')}. `;
  }

  if (minutes && !isTooltipText) {
    time += `${minutes} ${t('time.minute')}.`;
  } else {
    time += `<b>${minutes}</b> ${t('time.minute')}.`;
  }

  return time;
};

const selfExpiredTime = computed(() => {
  if (!props.product.self_license_expiration_date) {
    return 0;
  }

  return new Date(props.product.self_license_expiration_date).getTime();
});

const orgExpiredTime = computed(() => {
  if (!props.product.license_expiration_date) {
    return 0;
  }

  return new Date(props.product.license_expiration_date).getTime();
});

const calcExpiredTime = (date: number) => Math.floor(
  (date - new Date().getTime()) / 1000
);

const remainedTimeSeconds = computed(() => {
  if (!selfExpiredTime.value) {
    return false;
  }

  return calcExpiredTime(selfExpiredTime.value);
});

const isSoonExpire = computed(() => {
  if (!remainedTimeSeconds.value) {
    return false;
  }

  return (remainedTimeSeconds.value / 60 / 60) < 48;
});

const isOrganisationLicence = computed(
  () => props.product.license_state === LicenseState.Organization
);

const hasActiveOrgLicense = computed(
  () => isOrganisationLicence.value && orgExpiredTime.value > (new Date()).getTime()
);

const showStatistics = computed(
  () => props.product.license_state
      || props.product.product_last_start
      || props.product.self_license_expiration_date
);

const expirationDate = computed(() => {
  if (!remainedTimeSeconds.value || hasActiveOrgLicense.value) {
    return false;
  }

  if (remainedTimeSeconds.value < 0) {
    return `${t('tooltipLicenseExpired')} ${formatDate(dayjs(selfExpiredTime.value))}`;
  }

  if (isSoonExpire.value) {
    return `${t('tooltipLicense')} ${t('tooltipAfter')} ${formatTime(remainedTimeSeconds.value)}`;
  }

  return `${t('tooltipLicense')} ${formatDate(selfExpiredTime.value)}`;
});

const productSpendTime = computed(() => {
  const spendTime = props.product.product_active_duration;

  if (!spendTime) {
    return '<b>–</b>';
  }

  return spendTime < 60 ? `<b>${t('lessOneMinute')}</b>` : formatTime(spendTime, true);
});

const productLastLaunch = computed(() => {
  if (!props.product.product_last_start) {
    return '–';
  }

  return `${dayjs(props.product.product_last_start).format('DD.MM.YYYY HH:mm')}`;
});

const tooltipSpendTime = computed(() => {
  let data = `${t('tooltipSpendTime')}: ${productSpendTime.value}</br>`;
  data += `${t('tooltipLastLaunch')}: <b>${productLastLaunch.value}</b>`;

  return data;
});
</script>

<style lang="scss">
@import "@/assets/style/include.scss";

.licence-info {
  &:not(:empty) {
    height: 36px;
    background: $color-white;
    font-size: 16px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    overflow: hidden;
    background: linear-gradient(15deg, #FFF 10%, #CFCFCF 90%) padding-box, $borderGradient;
    border: 1px solid transparent;
    border-bottom: none;
  }

  &-text {
    padding: 0 16px;
    display: flex;
    align-items: center;
  }

  &-alert {
    width: 19px;
    height: 18px;
    background: url("@/assets/img/alert.svg");
    margin-right: 4px;
  }

  &__calendar {
    height: 100%;
    background: linear-gradient(15deg, #F3F3F3 10%, #B8B8B8 90%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;

    svg {
      width: 24px;
      height: 24px;
      margin: 0 6px;
    }

    &.--small {
      padding: 0 5px;

      svg {
        margin: 0;
      }

      .licence-info__calendar-text {
        display: none;
      }
    }
  }
}

@include media-breakpoint-down("mobile") {
  .licence-info {
    height: 28px;

    &-text {
      white-space: nowrap;
    }
  }
}
</style>
