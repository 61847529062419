export default {
  "portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portal"])},
  "vrLabs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtual laboratories"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "loader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["loading..."])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
  "sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["expired"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
  "half_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 months"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
  "notSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["not selected"])},
  "goByLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
  "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])},
  "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published"])},
  "notPublished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not published"])},
  "publication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the publication"])},
  "toMainPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To the main page"])},
  "titleAnnouncement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product announcement"])},
  "titleProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating a product"])},
  "EditProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editing a product"])},
  "tabMainData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic information"])},
  "tabInfoData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Information"])},
  "addProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adding a product"])},
  "labelImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images"])},
  "labelAnnouncement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcement"])},
  "labelVideos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Instructions"])},
  "labelDocs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
  "labelFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
  "tooltipSyncLocal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronize <br> with localization"])},
  "tooltipSpendTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total time in the laboratory"])},
  "tooltipLastLaunch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of the last launch"])},
  "tooltipLicense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The license is about to expire"])},
  "tooltipLicenseExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The license has expired"])},
  "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics"])},
  "tooltipLaunchHasLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The launch limit has been reached"])},
  "tooltipAfter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["after"])},
  "tooltipLockNewProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only the Administrator<br>can add new products"])},
  "tooltipLogIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please log in to launch demo"])},
  "lessOneMinute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["less 1 min"])},
  "requiredField": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Field ", _interpolate(_named("fieldName")), " required"])},
  "ruleEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please input correct email address"])},
  "ruleUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please input correct url address"])},
  "ruleMoreZero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be greater than 0"])},
  "successSaveAnnouncement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcement successfully saved"])},
  "successSaveProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product successfully saved"])},
  "createProductCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating a product card"])},
  "someText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have enough perseverance, publish the lab in one attempt"])},
  "someText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["And this is an option, if you need to urgently make an announcement."])},
  "regData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration data"])},
  "btnBold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bold"])},
  "btnItalic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italic"])},
  "btnCross": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross out"])},
  "btnAddLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add link"])},
  "btnAddCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add code"])},
  "btnH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heading"])},
  "btnP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paragraph"])},
  "btnUnnumberedList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unnumbered list"])},
  "btnNumberedList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numbered list"])},
  "btnCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code Block"])},
  "btnQuote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quote"])},
  "btnHorizontalDivider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal divider"])},
  "btnNewLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New line"])},
  "btnClear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear formatting"])},
  "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "btnRepeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat"])},
  "btnSetLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install/Fix the link"])},
  "textToMarket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe right now and get access to the lab!"])},
  "textToMarketMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe right now!"])},
  "confirmRemove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the product?"])},
  "fields": {
    "app_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to the application"])},
    "supported_locales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application languages"])},
    "section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chapter"])},
    "subsection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "img_cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card Image"])},
    "demo_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demo mode"])},
    "demo_mode_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of launches"])},
    "has_workbook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notebook availability "])},
    "workbook_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notebook link "])},
    "registry_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registry name"])},
    "registry_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registry number"])},
    "article_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article number"])},
    "img_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Background image"])},
    "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application Icon"])},
    "price_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price per month"])},
    "discount_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount per month"])},
    "discount_per_half_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount per half year"])},
    "discount_per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount per year"])},
    "labelName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "labelDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "labelAuthorSign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Author signature"])},
    "labelSystemReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System requirements"])},
    "labelIndividualSystemReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual SR"])},
    "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Button text"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])}
  },
  "buttons": {
    "addByLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add by link"])},
    "loadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload a file"])},
    "addLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a link"])}
  },
  "addApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add an application"])},
  "commonVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General video instructions"])},
  "commonDocs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General documents"])},
  "commonSysReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard system requirements"])},
  "systemReqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard system reqs."])},
  "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "searching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Searching"])},
  "activeLicense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active licenses"])},
  "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate"])},
  "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied!"])},
  "copyToClipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["copy to clipboard"])},
  "date_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
  "date_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration date"])},
  "paymentSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment was successful"])},
  "typeTextToFind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter text to find"])},
  "sessionCloseByServer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session expired. You need to go through the authentication process again."])},
  "pageNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, the page you are looking for is not found 🥺"])},
  "accessDeniedPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops, it seems you can't access the page 😕"])},
  "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter"])},
  "alerts": {
    "sessionClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The session is closed"])},
    "sessionCloseText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, this happens. To return, log in under your account."])},
    "fileNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The file was not found, download it again"])},
    "fileLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploading the file..."])},
    "fileExtension": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Allowed data format ", _interpolate(_named("extension"))])},
    "missingFileLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The file name or link is missing"])},
    "leavePageWithUnsavedData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes you made may not be saved"])}
  },
  "pageTitles": {
    "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prices"])},
    "Shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop"])},
    "Laboratories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "Laboratory": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Laboratory ", _interpolate(_named("name"))])},
    "Subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriptions"])},
    "SessionClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Closed"])},
    "Administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])},
    "AdministrationUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration ", "|", " Users"])},
    "AdministrationUsersUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration ", "|", " User"])},
    "AdministrationLicenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration ", "|", " Licenses"])},
    "AdministrationRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration ", "|", " Requests"])},
    "AdministrationUserCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration ", "|", " User Creation"])},
    "AdministrationLimb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration ", "|", " Limb"])},
    "AdministrationOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration ", "|", " Institution"])},
    "AdministrationOrgOwner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration ", "|", " Institution Owner"])},
    "AdministrationOrgUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration ", "|", " Institution Users"])},
    "AdministrationOrgUsersUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration ", "|", " Institution User"])},
    "AdministrationOrgOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration ", "|", " Institution Purchases"])},
    "AdministrationOrgRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration ", "|", " Institution Requests"])},
    "AdministrationOrgRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration ", "|", " Institution Request"])},
    "AdministrationOrgLicenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration ", "|", " Institution Licenses"])},
    "AdministrationOrgUserCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration ", "|", " Institution User Creation"])},
    "AdministrationOrganizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration ", "|", " Institutions"])},
    "AdministrationOrgCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration ", "|", " Institution Creation"])}
  },
  "labels": {
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "patronymic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patronymic"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website and applications language"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
    "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution"])},
    "organization1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution"])},
    "studyClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class"])},
    "organizationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution name"])},
    "userType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "localRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local roles"])},
    "requestedRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested role"])},
    "requestedClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested class"])},
    "fileAttach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach file"])},
    "groupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group name"])},
    "addGroupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a group"])},
    "notSpecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not specified"])},
    "classNotSpecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No grade"])},
    "groupNotSpecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No group"])},
    "founderData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Founder info"])},
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transitions"])},
    "tournament": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tournament"])},
    "workbook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notebooks"])},
    "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving"])}
  },
  "languages": {
    "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Русский"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])},
    "hy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Հայերեն"])}
  },
  "roles": {
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
    "moderator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderator"])},
    "marketer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketer"])},
    "org_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution owner"])},
    "org_moderator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution moderator"])},
    "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher"])},
    "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])}
  },
  "validations": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field is required."])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Field must contain at least ", _interpolate(_named("min")), " symbols."])},
    "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Field must contain a maximum of ", _interpolate(_named("max")), " symbols."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field must be a valid email."])},
    "datepickerRangeOverflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date value is too big"])},
    "datepickerRangeUnderflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date value is too small"])},
    "datepickerTypeMismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the date in the format dd.mm.yyyy"])},
    "valueMissing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value is required"])},
    "incorrectGroupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect group name"])}
  },
  "filters": {
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "vizex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code Place"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
    "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected"])},
    "records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "buttons": {
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trial"])}
    }
  },
  "menus": {
    "laboratories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laboratories"])},
    "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop"])},
    "administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])},
    "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriptions"])},
    "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prices"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "licenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licenses"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchases"])},
    "requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requests"])},
    "organizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institutions"])},
    "limb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limb"])},
    "ownersRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requests for founders"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])}
  },
  "footer": {
    "publicOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License Agreement"])},
    "termsOfUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Agreement"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "systemRequirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System requirements"])},
    "personalDataProcessingRules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "version": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ver.: ", _interpolate(_named("version"))])},
    "copyright": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["© Zenkova Olga Alexandrovna, ", _interpolate(_named("date")), " © 42 LLC, ", _interpolate(_named("date"))])},
    "copyrightInternational": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["© Code Place Ltd., 2023–", _interpolate(_named("date"))])}
  },
  "license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licence"])},
  "licenses": {
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual"])},
    "organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From institution"])}
  },
  "autoPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto payment"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
  "packet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pack"])},
  "allInclusive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All-inclusive"])},
  "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe"])},
  "issueTill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issue until"])},
  "areYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure?"])},
  "ban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block"])},
  "unban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unblock"])},
  "banUnban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block / Unblock"])},
  "createNewOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add user"])},
  "orgKick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expel from institution"])},
  "leaveOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclusion"])},
  "createGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a group"])},
  "createModerator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new moderator"])},
  "createOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an institution"])},
  "changeOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Institution"])},
  "deleteOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete institution"])},
  "deleteUserModalTitle": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Deleting users"]), _normalize(["Deleting a user"]), _normalize(["Deleting an institution"])])},
  "deleteUserModalText": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Be careful, this action is irreversible, deleted users cannot be recovered."]), _normalize(["Are you sure you want to delete the user?"]), _normalize(["Be careful, this action is irreversible, the deleted institution cannot be restored. Users will be exclusion from the institution."])])},
  "banUserModalTitle": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Blocking of users"]), _normalize(["Blocking of a user"]), _normalize(["Blocking of the founder"])])},
  "banUserModalText": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["By blocking the user, you will restrict their access to the labs. If the user is a founder, then they will not be able to manage their institution."]), _normalize(["By blocking a user, you will restrict their access to the labs."])])},
  "banReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for block"])},
  "unbanUserModalTitle": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Restore user access"]), _normalize(["Restore founder access"])])},
  "unbanUserModalText": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["The user will gain access to the labs."]), _normalize(["The founder will be able to manage the institution."])])},
  "kickUserFromOrgModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclusion from the institution"])},
  "kickUserFromOrgModalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An exception will result in the loss of the user's access to the issued licenses."])},
  "usersTableColumns": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
    "org": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution"])},
    "added_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created on"])},
    "registered_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration type"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licenses"])}
  },
  "orgsTableColumns": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Members"])},
    "licenses_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licenses"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Founder"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created on"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])}
  },
  "requestsTableColumns": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request #"])},
    "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created on"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name, Last Name"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decision"])},
    "moderator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processed by"])},
    "statusDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decided on"])}
  },
  "numerals": {
    "labs": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("nn")), " laboratory"]), _normalize([_interpolate(_named("nn")), " laboratories"]), _normalize([_interpolate(_named("nn")), " laboratories"])])},
    "students": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["student"]), _normalize(["students"]), _normalize(["students"])])},
    "teachers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["teacher"]), _normalize(["teachers"]), _normalize(["teachers"])])},
    "users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["user"]), _normalize(["user"]), _normalize(["users"])])},
    "createUsers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["user"]), _normalize(["user"]), _normalize(["users"])])},
    "moderators": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["moderator"]), _normalize(["moderators"]), _normalize(["moderators"])])}
  },
  "emailNotVerified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email awaiting confirmation"])},
  "somethingWentWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong..."])},
  "userCreateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User was successfully created"])},
  "userCreateAnotherOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create another one"])},
  "emptyUserName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["User #", _interpolate(_named("id"))])},
  "nothingFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nothing found"])},
  "createOrganization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation of an institution"])},
  "organizationCreateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution successfully created"])},
  "organizationCreateAnotherOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create another one"])},
  "laboratory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laboratory"])},
  "expires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expires"])},
  "auto-renewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto renewal"])},
  "demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trial"])},
  "youHaveNLaunchesLeft": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["You have ", _interpolate(_named("nn")), " launch left"]), _normalize(["You have ", _interpolate(_named("nn")), " launches left"]), _normalize(["You have ", _interpolate(_named("nn")), " launches left"])])},
  "usersListTableLeaveBefore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you go to a user's page, the selection on this page will be lost"])},
  "AdministrationOrdersConfirmTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment confirmation"])},
  "AdministrationOrdersConfirmText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmed payment cannot be canceled"])},
  "AdminOrgOrdersToastOrderConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase has been paid!"])},
  "ToastOrderDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase has been deleted!"])},
  "ToastSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved!"])},
  "OrgShopToastError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in all fields correctly"])},
  "ShopOrgTableAddNewProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add lot"])},
  "ShopOrgTableAddNewProductError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save the purchase order before creating a new one"])},
  "ShopSendOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your purchase order has been sent"])},
  "universalEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject"])},
  "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
  "declineRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel the request"])},
  "acceptRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save and accept request"])},
  "actionConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm the action"])},
  "statuses": {
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awaiting"])},
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted"])},
    "declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])}
  },
  "searchByContext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Context search"])},
  "searchByName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by name"])},
  "chosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chosen"])},
  "notEnoughLicensesToDoThisAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["not enough licenses to operate"])},
  "fileExtensionError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The file \"", _interpolate(_named("name")), "\" has an invalid format (", _interpolate(_named("extension")), ")."])},
  "exportFromFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
  "importFileWithError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download the file with errors"])},
  "exported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exported"])},
  "subscribePage": {
    "confirmText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To activate the autopayment, you need to make a purchase for 1 rub to confirm the card."])},
    "emptyPageText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, you don't have paid subscriptions yet, please visit the store, there are many interesting things there!"])},
    "packetText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laboratories that are available to you:"])},
    "toShop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To the store"])}
  },
  "shop": {
    "invoiceName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["# ", _interpolate(_named("num"))])},
    "textNotAuthorizedUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To subscribe to the laboratory, create an account and confirm your email"])},
    "autopaymentLabelAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["auto payment (can be canceled later in your account)"])},
    "nextPayment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Next payment ", _interpolate(_named("date")), ":"])},
    "nextPaymentSum": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("sum")), " per month"]), _normalize([_interpolate(_named("sum")), " per 6 months"]), _normalize([_interpolate(_named("sum")), " per year"])])},
    "buy": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Buy selected"]), _normalize(["Buy"])])},
    "personalDataProcessing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal data processing"])},
    "publicOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License Agreement"])},
    "termsOfUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Agreement"])},
    "personalDataProcessingRules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "personalDataProcessingInfo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["By continuing the purchase, you agree to the terms ", _interpolate(_list(0)), ", ", _interpolate(_list(1)), " and ", _interpolate(_list(2)), "."])},
    "periodPayment": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["when buying all laboratories for 1 month"]), _normalize(["when buying all laboratories for 6 months"]), _normalize(["when buying all laboratories for 12 months"])])},
    "tillDate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["until ", _interpolate(_named("date"))])},
    "addOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add item"])},
    "sendOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
    "license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License"])},
    "createOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a purchase order"])},
    "orgEmptyText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To access the labs and distribute licenses to your students and teachers, complete your purchase order and follow the instructions."])},
    "orgEmptyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need access for an institution?"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["amount"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty"])},
    "closeShop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The store is currently closed"])},
    "unavailableTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Buy Virtual Laboratories for Your School and Students Now!</b>"])},
    "unavailableText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, contact us if you want to purchase a license at:"])},
    "unavailableForUpdatesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>The price of a license per user: $448/year.</b>\n\nThe Virtual Laboratories software — 15 laboratories — is delivered based on the SaaS (software as a service) model, i.e. it is downloaded from the Internet via a web browser rather than installed on separate computers. Each user has their personal account where they can store downloaded scenes and share them with other users.\n\nA license for the Virtual Laboratories software is purchased for each individual user, whether they are a teacher or a student, so that they have access from both school and home computers. It is forbidden to use one license by several users.\n\nWe offer personalized flexible discounts for licenses purchased for a school or a class, depending on the number of individual licenses.\n\nThe price list for licenses is sent as an official commercial offer upon prior request to  <a href=\"mailto:infoviz", "@", "answer-42.ru\">infoviz", "@", "answer-42.ru</a>.\n\n<b>An online payment method for natural persons will be added later.</b>\n\n<i style=\"opacity: 0.5\">The information is not a public offer. All prices are given in accordance with the current price list of the right holder. The right holder reserves the right to change the prices at any time.</i>\n"])},
    "laboratories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laboratories"])},
    "ordersListTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase History"])},
    "notPaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not paid"])},
    "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
    "noStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undefined"])}
  },
  "administration": {
    "createUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new user"])},
    "classNames": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["F"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["H"])}
    ],
    "org": {
      "actions": {
        "kick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detach"])},
        "kickModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclusion from the institution"])},
        "kickModalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be careful. Users will lose access to licenses issued to them by the institution."])},
        "kickModalTextSingle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be careful. The user will lose access to licenses issued by the institution."])},
        "waitingQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting in line..."])},
        "creatingUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating users..."])},
        "usersCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users are created..."])},
        "handleErrors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error handling..."])},
        "exportingFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporting a file"])},
        "downloadFileWithErrors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download a document with errors"])},
        "brokenFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The file does not meet the requirements, try another file."])},
        "accountsCreated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Accounts Created: ", _interpolate(_named("created")), " of ", _interpolate(_named("count"))])},
        "accountsCreatedText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Failed to create ", _interpolate(_named("error")), " user.We pointed out the errors in the corresponding lines. Correct the errors and upload the document again."])}
      },
      "fileFormatError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The uploaded document does not match the required format"])},
      "fileName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["File from ", _interpolate(_named("date"))])},
      "createGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create user group (XLS)"])},
      "inviteLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite link"])},
      "userRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User's request: "])},
      "changeGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change group"])},
      "changeClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change class"])},
      "groupDeleteConfirmation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["To remove ", _interpolate(_list(0)), ", type its name in the field below."])},
      "noInviteLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have an active link to join your educational institution at the moment."])},
      "infoTooltip": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["This button opens the information panel for your institution."]), _normalize(["Here you can create and edit groups of your students and teachers. You can manage branches or separate moderators from students."]), _normalize(["To share a link to join your Institution, click the button on the right"])])},
      "actionFileCreateTooltip": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["To add several users at once, upload the \".xls\" file (Excel table). The contents of the file must contain the full name, role and e-mail of each user. Users will receive invitations to register and enter the educational institution."]), _normalize(["If your student doesn't have an e-mail address, you can send them a QR code or invitation link."])])},
      "downloadFileTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download .xls template"])}
    },
    "limb": {
      "registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registered on"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banned"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
      "banned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocked"])}
    }
  },
  "profile": {
    "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password >"])},
    "waitingForConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for confirmation"])},
    "myProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My profile"])},
    "deleteModalMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Are you sure you want to delete your account? If you have any problems with your account, write to us at ", _interpolate(_list(0)), " and we will try to help you."])},
    "subscribeMailing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe to the newsletter"])},
    "deleteModalMessageOrg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Are you sure you want to delete your account and institution? If you have any problems with your account, write to us at ", _interpolate(_list(0)), " and we will try to help you."])},
    "deleteModalHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting account"])},
    "deleteModalHeaderOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting institution"])},
    "restoreButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore Account"])},
    "deleteButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete account"])},
    "deleteButtonOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete institution"])},
    "revokeOrgRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel the request"])},
    "revokeOrgRequestText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want to cancel your request to join “", _interpolate(_named("org")), "”?"])},
    "leaveOrgButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disengage from school >"])},
    "leaveOrgModalHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leaving the institution"])},
    "leaveOrgModalConfirmButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave"])},
    "leaveOrgText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you leave an institution, the subscriptions issued to you by that institution will no longer be available to you."])},
    "sendRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit a request"])},
    "modalTitleRestoringUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restoring user"])},
    "modalTextRestoringUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to restore this user?"])},
    "attachToOrgText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Want to apply to join “", _interpolate(_named("org")), "”?"])},
    "disabledDeleteOrgTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot delete an institution while it has someone other than the founder"])},
    "accepted": {
      "applicationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request is accepted!"])},
      "applicationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have accepted your request. You can see the status of the request or cancel it in your profile"])}
    },
    "consists": {
      "applicationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are already a member of this institution"])},
      "applicationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulations, nothing bad happened. You were just trying to re-join an institution you already belong to"])}
    },
    "consists-another": {
      "applicationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are already a member of the institution"])},
      "applicationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, you cannot join more than one institution at the same time. If you want to change institutions, leave your current one first."])}
    },
    "confirmation-await": {
      "applicationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request is pending"])},
      "applicationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have accepted your request for admission to the educational institution. Your request is pending"])}
    },
    "incorrect-role": {
      "applicationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, you can't follow this link"])},
      "applicationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change your profile role or ask the administrator to change it"])}
    }
  },
  "price": {
    "currencyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USD"])},
    "currencySign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$"])},
    "monthPrice": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("price.currencySign", undefined, _type), " / 1 month"])},
    "halfYearPrice": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("price.currencySign", undefined, _type), " / 6 months"])},
    "yearPrice": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("price.currencySign", undefined, _type), " / 1 year"])},
    "salePercent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discount, %"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])}
  },
  "labs": {
    "section": {
      "labs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laboratories"])},
      "teaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teaching aids"])},
      "scientific_stands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scientific stands"])},
      "tournaments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tournaments"])}
    },
    "licenseState": {
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trial"])},
      "own": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribed"])},
      "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granted by org"])}
    },
    "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent"])},
    "emptySearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, nothing was found for your request. Maybe you can search for something in our library?"])},
    "subscribed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribed"])},
    "soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soon"])},
    "fromPrice": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["from ", _interpolate(_named("price"))])},
    "launch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Launch"])},
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe"])},
    "workbook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workbook"])},
    "demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demo"])},
    "virtualLab": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Virtual laboratory \"", _interpolate(_named("name")), "\""])},
    "clearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "mobileModalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The app is not yet available on mobile devices."])},
    "oops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops..."])}
  },
  "time": {
    "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["h"])},
    "minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["m"])}
  },
  "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
  "prepositions": {
    "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["at"])}
  }
}